<template>
  <v-icon left :color="iconColorClass">
    {{ iconTask }}
  </v-icon>
</template>

<script>
// model
import {
  isValidTaskEntries,
  taskValidationColorClass
} from "@/model/workflow/task/taskModel";

// mixins
import { taskIconMixin } from "@/mixins/shared/workflow/task/taskIconMixin";

export default {
  name: "NewTaskIcon",
  mixins: [taskIconMixin],
  props: {
    /**
     * user task template
     * @type {{id: number, name: string, sequence: number, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}, duration: {calendar: string, duration: string, fixed: boolean, milestone: boolean}}}
     */
    task: undefined
  },
  computed: {
    /**
     * icon Color Class
     * @return {String|string|undefined}
     */
    iconColorClass() {
      return this.task
        ? taskValidationColorClass(isValidTaskEntries(this.task))
        : undefined;
    }
  }
};
</script>
